import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { orgLocalDateTime } from "utils/dateHelpers"

const ActionLabel = ({ action }) => {
  const failPattern = /.*failure/g;
  const impersonatePattern = /.*impersonat.*/g;
  const signInPattern = /.*sign_in.*/g;

  const getStyleClass = () => {
    if (action.match(failPattern)) {
      return "font-semibold text-pink-400 bg-pink-100"
    }
    if (action.match(impersonatePattern)) {
      return "font-medium text-gray-800 bg-orange-200"
    }
    if (action.match(signInPattern)) {
      return "font-semibold text-green-400 bg-green-100"
    }

    return "font-semibold bg-gray-200"
  }

  return (
    <span className={clsx("text-xs inline-block py-1 px-2 rounded uppercase",
      getStyleClass())}
    >
      {action}
    </span>
  )
}

ActionLabel.propTypes = {
  action: types.string.isRequired,
}

const AuditItem = ({ audit }) => {
  const {
    remoteAddress, impersonator, action, createdAt, organizationName,
  } = audit

  return (
    <tr className="h-16 rounded-tr bordered-table-row">
      <td className="pl-5">
        <ActionLabel action={action} />
      </td>
      <td>
        <div>{ orgLocalDateTime(createdAt) }</div>
      </td>
      <td>
        <div>{ remoteAddress }</div>
      </td>
      <td>{ impersonator?.fullName }</td>
      <td>{ organizationName }</td>
    </tr>
  )
}

AuditItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  audit: types.object.isRequired,
}

export default AuditItem
