/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import { useOnClickOutside } from "hooks"

const GlobalHeaderDropdown = ({
  children, dropdownStyles = "", trigger, triggerContainerStyles = "",
}) => {
  const triggerRef = useRef()
  const dropdownRef = useRef()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const openDropdown = () => setDropdownOpen(true)
  const closeDropdown = () => setDropdownOpen(false)

  const onClickOutside = (event) => {
    if (triggerRef.current.contains(event.target)) return

    closeDropdown()
  }

  useOnClickOutside(dropdownRef, onClickOutside)

  return (
    <>
      <div ref={triggerRef} className={triggerContainerStyles} onClick={dropdownOpen ? closeDropdown : openDropdown}>
        {trigger}
      </div>
      {
        dropdownOpen && (
          <div
            ref={dropdownRef}
            className={clsx(dropdownStyles, "bg-white absolute top-[57px] right-0 max-w-96 w-full rounded-lg lg:w-96")}
            style={{ boxShadow: "rgba(0, 0, 0, 0) 0px 0px, rgba(90, 93, 131, 0.2) -1rem 1rem 2rem -1rem" }}
          >
            {children}
          </div>
        )
      }
    </>
  )
}

GlobalHeaderDropdown.propTypes = {
  children: types.node.isRequired,
  dropdownStyles: types.string,
  trigger: types.node.isRequired,
  triggerContainerStyles: types.string,
}

export default GlobalHeaderDropdown
