import { useEffect } from "react"
import types from "prop-types"
import { store } from "store"
import { setInitialSettings } from "reduxSlices/currentSettingsSlice"

const SetupGeneralSettingsInRedux = ({
  organizationId,
  organizationColor,
  organizationShortName,
  organizationSubdomain,
  taskNotifySettings = [],
  taskReminders = undefined,
  timezone = undefined,
}) => {
  useEffect(
    () => {
      const initialSettings = {
        organizationId,
        organizationColor,
        organizationShortName,
        organizationSubdomain,
        timezone: timezone ?? undefined,
        taskReminders: taskReminders ?? undefined,
        taskNotifySettings: taskNotifySettings ?? [],
      }
      store.dispatch(setInitialSettings(initialSettings))
    },
    [timezone, taskReminders, taskNotifySettings],
  )

  return null
}

SetupGeneralSettingsInRedux.propTypes = {
  organizationId: types.number.isRequired,
  organizationColor: types.string.isRequired,
  organizationShortName: types.string.isRequired,
  organizationSubdomain: types.string.isRequired,
  timezone: types.string,
  taskReminders: types.string,
  taskNotifySettings: types.arrayOf(types.shape({
    isEnabled: types.bool,
    settingType: types.string,
  })),
}

export default SetupGeneralSettingsInRedux
