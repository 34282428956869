const protocol = () => window?.location.protocol || ""

const originWithReplacedSubdomain = (subdomain) => {
  const urlParts = window?.location.host.split(".")
  urlParts[0] = subdomain
  return `${protocol()}//${urlParts.join(".")}`
}

const origin = ({ subdomain } = {}) => {
  if (subdomain) return originWithReplacedSubdomain(subdomain)

  return window?.location.origin || ""
}

/* ********** ACTIVITY HUB ********** */
// static paths
export const SIGN_OUT_PATH = "/sign-out"
export const ACTIVE_STORAGE_DIRECT_UPLOADS_PATH = "/rails/active_storage/direct_uploads"
export const ADMIN_PATH = "/admin"
export const ADMIN_FACILITIES_PATH = "/admin/facilities"
export const ADMIN_SOLUTIONS_PATH = "/admin/solutions"
export const ADMIN_STOP_IMPERSONATING_PATH = "/php-admin/impersonations"
export const ADMIN_USERS_AND_GROUPS_PATH = "/admin/users-and-groups"
export const ACTIVITY_HUB_LOGIN_PATH = "/activity-hub/sign-in"
export const ACTIVITY_HUB_DASHBOARD_PATH = "/activity-hub/dashboard"
export const ACTIVITY_HUB_SUBMISSIONS_PATH = "/activity-hub/form-submissions"
export const ACTIVITY_HUB_TASKS_PATH = "/activity-hub/tasks"
export const ACTIVITY_HUB_FACILITY_AND_DEPT_IMPORTS_PATH = "/admin/facility-and-dept-imports/new"
export const ACTIVITY_HUB_FORMS_PATH = "/activity-hub/forms"
export const ACTIVITY_HUB_REPORTS_PATH = "/activity-hub/reports"
export const ACTIVITY_HUB_SURVEYS_PATH = "/activity-hub/surveys"
export const ACTIVITY_HUB_USERS_IMPORTS_PATH = "/admin/user-imports/new"
export const ACTIVITY_HUB_SEARCH_PATH = "/activity-hub/tasks-and-submissions/search"
export const ACTIVITY_HUB_REQUIRED_PASSWORD_RESET_PATH = "/activity-hub/required-password-reset"
export const ACTIVITY_HUB_RESET_PASSWORD_PATH = "/activity-hub/reset-password"
export const ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH = "/activity-hub/form-submission-reports"
export const ACTIVITY_HUB_NEW_FORM_SUBMISSION_REPORTS_PATH = "/activity-hub/form-submission-reports/new"
export const ACTIVITY_HUB_SOLUTION_LEVEL_REPORTS_PATH = "/activity-hub/solution-level-reports"
export const ACTIVITY_HUB_NEW_SOLUTION_LEVEL_REPORT_PATH = "/activity-hub/solution-level-reports/new"
export const ACTIVITY_HUB_SURVEY_REPORTS_PATH = "/activity-hub/surveys/survey-reports"
export const ACTIVITY_HUB_NEW_SURVEY_REPORT_PATH = "/activity-hub/surveys/survey-reports/new"
export const ACTIVITY_HUB_SURVEYS_NEW_SURVEY_CONFIGURATION_PATH = "/activity-hub/surveys/survey-configurations/new"
export const ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT = "/activity-hub/surveys/survey-configurations"
export const ACTIVITY_HUB_SURVEYS_SURVEY_BATCHES_ROOT = "/activity-hub/surveys/survey-batches"

// dynamic paths
export const activityHubSubmissionPath = (slug) => `/activity-hub/form-submissions/${slug}`
export const activityHubSubmissionAndTaskPath = (submissionSlug, taskId) => (
  `${activityHubSubmissionPath(submissionSlug)}?tab=Activities&taskId=${taskId}`
)
export const adminSolutionPath = (slug) => `/admin/solutions/${slug}`
export const publicSolutionPath = (slug) => `/solutions/${slug}`
export const publicFormPath = (slug, queryString = "") => `/forms/${slug}${queryString}`

/* ********** Form Submission Reports ********** */
export const formSubmissionReportExportLocation = (id) => `${ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH}/${id}/exports`
export const newFormSubmissionReportExportPath = (id) => `${formSubmissionReportExportLocation(id)}/new`
export const formSubmissionReportEditPath = (id) => `${ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH}/${id}/edit`
export const formSubmissionReportUpdatePath = (id) => `${ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH}/${id}`
export const formSubmissionScheduledReportExportsLocation = (id) => `${ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH}/${id}/scheduled-report-exports`
export const newFormSubmissionScheduledReportExportPath = (id) => `${formSubmissionScheduledReportExportsLocation(id)}/new`
export const editFormSubmissionScheduledReportExportPath = (reportId, exportId) => `${formSubmissionScheduledReportExportsLocation(reportId)}/${exportId}/edit`

/* ********** Solution Level Reports ********** */
export const solutionLevelReportEditPath = (id) => `${ACTIVITY_HUB_SOLUTION_LEVEL_REPORTS_PATH}/${id}/edit`
export const solutionLevelReportUpdatePath = (id) => `${ACTIVITY_HUB_SOLUTION_LEVEL_REPORTS_PATH}/${id}`
export const solutionLevelReportExportLocation = (id) => `${ACTIVITY_HUB_SOLUTION_LEVEL_REPORTS_PATH}/${id}/exports`
export const newSolutionLevelReportExportPath = (id) => `${solutionLevelReportExportLocation(id)}/new`
export const solutionLevelScheduledReportExportsLocation = (id) => `${ACTIVITY_HUB_SOLUTION_LEVEL_REPORTS_PATH}/${id}/scheduled-report-exports`
export const newSolutionLevelScheduledReportExportPath = (id) => `${solutionLevelScheduledReportExportsLocation(id)}/new`
export const editSolutionLevelScheduledReportExportPath = (reportId, exportId) => `${solutionLevelScheduledReportExportsLocation(reportId)}/${exportId}/edit`

/* ********** Survey Reports ********** */
export const surveyReportExportLocation = (id) => `${ACTIVITY_HUB_SURVEY_REPORTS_PATH}/${id}/exports`
export const newSurveyReportExportPath = (id) => `${surveyReportExportLocation(id)}/new`
export const surveyReportEditPath = (id) => `${ACTIVITY_HUB_SURVEY_REPORTS_PATH}/${id}/edit`
export const surveyReportUpdatePath = (id) => `${ACTIVITY_HUB_SURVEY_REPORTS_PATH}/${id}`
export const surveyScheduledReportExportsLocation = (id) => `${ACTIVITY_HUB_SURVEY_REPORTS_PATH}/${id}/scheduled-report-exports`
export const newSurveyScheduledReportExportPath = (id) => `${surveyScheduledReportExportsLocation(id)}/new`
export const editSurveyScheduledReportExportPath = (reportId, exportId) => `${surveyScheduledReportExportsLocation(reportId)}/${exportId}/edit`

export const surveyConfigurationPath = (id, activeTabName = null) => {
  const rootPath = `${ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT}/${id}`
  return activeTabName ? (`${rootPath}?tab=${activeTabName}`) : rootPath
}
export const surveyBatchPath = (id) => `${ACTIVITY_HUB_SURVEYS_SURVEY_BATCHES_ROOT}/${id}`
export const surveyBatchFromConfigPath = (id) => `${surveyBatchPath(id)}?crumb=config`

// full urls
export const publicFormUrl = (slug) => `${origin()}/forms/${slug}`
export const adminCategoryUrl = ({ subdomain, category }) => `${origin({ subdomain })}/admin/solutions/${category.slug}`
export const activityHubDashboardUrl = ({ subdomain }) => `${origin({ subdomain })}${ACTIVITY_HUB_DASHBOARD_PATH}`
export const activityHubSignInUrl = ({ subdomain }) => `${origin({ subdomain })}${ACTIVITY_HUB_LOGIN_PATH}`

/* ********** PHP ********** */
// dynamic paths
// eslint-disable-next-line max-len
export const PHP_LOGIN_PATH = "/"
export const PHP_RESET_PASSWORD_PATH = "/reset-password"

export const copyFormToOrganizationPath = (organizationSubdomain) => (
  `/organizations/${organizationSubdomain}/form-copies`
)

/* ********** PHP ********** */
export const redirectTo = (path, params = null) => {
  let urlParams = ""
  if (params) {
    params = new URLSearchParams(params)
    urlParams = `?${params.toString()}`
  }

  window.location.href = `${path}${urlParams}`
}

/* ********** Public ********** */
const FORM_SUBMISSION_VERIFICATION_PATH = (cacheKey, hasUploads) => `/form-submission-verifications/${cacheKey}?uploads=${hasUploads}`
const FORM_SUBMISSION_UPLOADS_PATH = (formSlug, formSubmissionSlug, uploadsToken) => `/forms/${formSlug}/form_submissions/${formSubmissionSlug}/upload-files?uploads_token=${uploadsToken}`
const FORM_SUBMISSION_CREATED_PATH = (formSlug, formSubmissionSlug) => `/forms/${formSlug}/form_submissions/${formSubmissionSlug}`
export const surveySubmissionsUploadsPath = (uploadsToken) => {
  if (uploadsToken) {
    return `/surveys/upload-files?uploads_token=${uploadsToken}`
  }
  return "/surveys/upload-files"
}
export const SURVEY_SUBMISSION_CREATED_PATH = "/surveys/success"

export const redirectPathAfterFormSubmission = (response, formSlug, hasUploads) => {
  const {
    cacheKey, formSubmissionSlug, uploadsToken, survey,
  } = response.data

  if (cacheKey) {
    return FORM_SUBMISSION_VERIFICATION_PATH(cacheKey, hasUploads)
  }

  if (survey) {
    if (hasUploads) {
      return surveySubmissionsUploadsPath(uploadsToken)
    }
    return SURVEY_SUBMISSION_CREATED_PATH
  }

  if (formSubmissionSlug) {
    if (hasUploads) {
      return FORM_SUBMISSION_UPLOADS_PATH(formSlug, formSubmissionSlug, uploadsToken)
    }
    return FORM_SUBMISSION_CREATED_PATH(formSlug, formSubmissionSlug)
  }

  return null
}
/* ********** Public end ********** */

export const openInNewTab = (url) => window.open(url, "_blank")
