import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOrganizationShortName, setShortName } from "reduxSlices/currentSettingsSlice"
import { useModal } from "hooks"
import * as API from "services/api"
import CircularIconButton from "shared/buttons/CircularIconButton"
import ItemRow from "shared/ItemRow"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import { TextInput } from "shared/inputs"
import { errorToast } from "shared/toast"
import Modal, { ModalHeader } from "shared/Modal"

const ShortName = () => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const { modalIsOpen, openModal, closeModal } = useModal()
  const currentShortName = useSelector(getOrganizationShortName)
  const [newShortName, setNewShortName] = useState(currentShortName)

  const updateShortName = async (event) => {
    event.preventDefault()
    const scrubbedName = newShortName.trim()
    const response = await API.updateOrganizationShortName({ shortName: scrubbedName })

    if (response.ok) {
      dispatch(setShortName(scrubbedName))
      closeModal()
      setNewShortName(scrubbedName)
    } else {
      errorToast("Something went wrong.  Unable to update short name.")
    }
  }

  const openEditor = () => {
    openModal()
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 20)
  }

  const cancel = () => {
    closeModal()
    setNewShortName(currentShortName)
  }

  return (
    <>
      <ItemRow
        className="dark content-bar"
        itemRowHeaderContent={(
          <>
            <span className="content-bar-title">Organization Short Name</span>
            <span>{currentShortName}</span>
          </>
        )}
        itemRowHeaderControls={<CircularIconButton icon="pencil" onClick={openEditor} />}
      />
      <Modal isOpen={modalIsOpen}>
        <ModalHeader closeModal={cancel} heading="Organization Short Name" />
        <form onSubmit={updateShortName}>
          <TextInput ref={inputRef} className="bordered-base-input mb-4" value={newShortName} onChange={setNewShortName} />
          <div className="flex justify-center gap-2">
            <TertiaryButton text="Cancel" onClick={cancel} />
            <PrimaryButton text="Save" type="submit" disabled={!newShortName.trim()} />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ShortName
