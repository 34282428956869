import { snakeToCapitalCase } from "./stringHelpers"

export const STYLE = {
  background: "white",
  borderRadius: "0.375rem",
  height: "42px",
  minWidth: "330px",
}

// Matches TimezoneSetting::US_TIMEZONES in app/models/timezone_setting.rb

export const US_TIMEZONES = [
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
]

// Matches task_reminders in app/models/organization.rb

export const FREQUENCIES = [
  "no_reminders",
  "daily",
  "every_other_day",
  "weekly",
]

// Matches TASK_DEFAULT_TYPES in app/models/notification_setting.rb

export const ASSIGNEES_ON_ASSIGNMENT = "assignees_on_assignment"
export const ASSIGNEES_ON_COMPLETE = "assignees_on_complete"
export const MEMBERS_ON_ASSIGNMENT = "members_on_assignment"
export const MEMBERS_ON_COMPLETE = "members_on_complete"

export const notificationSettingLabel = (value) => {
  switch (value) {
  case ASSIGNEES_ON_ASSIGNMENT:
    return "Assignees: Task Assigned"
  case ASSIGNEES_ON_COMPLETE:
    return "Assignees: Task Completed"
  case MEMBERS_ON_ASSIGNMENT:
    return "Members: Task Assigned"
  case MEMBERS_ON_COMPLETE:
    return "Members: Task Completed"
  default:
    return snakeToCapitalCase(value)
  }
}

export const TASK_NOTIFICATION_OPTIONS = [
  { label: "Assignees: Task assigned", value: ASSIGNEES_ON_ASSIGNMENT },
  { label: "Assignees: Task completed", value: ASSIGNEES_ON_COMPLETE },
  { label: "Members: Task assigned", value: MEMBERS_ON_ASSIGNMENT },
  { label: "Members: Task completed", value: MEMBERS_ON_COMPLETE },
]
