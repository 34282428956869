import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import IconTapTarget from "shared/IconTapTarget"
import SearchPanel from "./SearchPanel"
import GlobalHeaderDropdown from "../GlobalHeaderDropdown"

const TasksAndSubmissionsSearch = () => (
  <GlobalHeaderDropdown
    dropdownStyles="lg:right-[115px] p-4"
    trigger={(
      <IconTapTarget className="flex justify-center items-center">
        <SvgSpriteIcon
          iconName="search"
        />
      </IconTapTarget>
    )}
  >
    <SearchPanel />
  </GlobalHeaderDropdown>
)

export default TasksAndSubmissionsSearch
