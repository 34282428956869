import types from "prop-types"
import { DEPARTMENT_SEGMENTS } from "utils/departmentHelpers"
import { CONTROLS } from "utils/QuestionBranching"
import {
  DEPARTMENT_FACILITY_MAPPING_OPERATOR_DATA_KEY,
} from "Forms/FormElements/QuestionBranchingConditionalCheck/OperatorDataProviders/operatorDataKeys"
import { SEGMENTS } from "./departmentSegmentHelpers"

const attributesShape = types.shape()

export const auditShape = types.shape({
  auditableType: types.string.isRequired,
  associatedType: types.string,
  createdAt: types.string.isRequired,
  action: types.string.isRequired,
  auditedChanges: types.object.isRequired,
  userId: types.number,
  user: types.object,
  impersonator: types.object,
  task: types.object,
  taskMember: types.object,
  taskAssignee: types.object,
  attachment: types.object,
  attachmentName: types.string,
  fromStatus: types.string,
  toStatus: types.string,
  grantee: types.object,
  comment: types.string,
  relatedFormSubmissionSlug: types.string,
})

export const conditionShape = types.shape({
  questionUuid: types.string,
  operator: types.string,
  matchValue: types.oneOfType([types.string, types.number, types.arrayOf(types.string)]),
})

export const conditionalsShape = types.shape({
  control: types.oneOf(CONTROLS),
  conditions: types.arrayOf(conditionShape),
})

export const formShape = types.shape({
  title: types.string,
  icon: types.string,
  iconColor: types.string,
})

export const formOptionShape = types.shape({
  id: types.number.isRequired,
  title: types.string.isRequired,
})

export const sessionUserShape = types.shape({
  email: types.string,
  full_name: types.string,
  role: types.string,
})

export const sessionDataShape = types.shape({
  user: sessionUserShape,
  impersonator: sessionUserShape,
})

export const taskShape = types.shape({
  id: types.number.isRequired,
  title: types.string.isRequired,
  dueDate: types.string.isRequired,
  description: types.string.isRequired,
  subform: formShape,
  isOpen: types.bool.isRequired,
  isOverdue: types.bool.isRequired,
})

export const questionShape = types.shape({
  attributes: attributesShape,
  condition: conditionShape,
  description: types.string.isRequired,
  prompt: types.string.isRequired,
  required: types.bool.isRequired,
  showDescription: types.bool.isRequired,
  type: types.string.isRequired,
  uuid: types.string.isRequired,
})

export const sectionShape = types.shape({
  conditionals: conditionalsShape,
  description: types.string,
  name: types.string,
  questions: types.arrayOf(questionShape),
  uuid: types.string,
})

export const submissionStatusShape = types.shape({
  id: types.number,
  status: types.string,
  name: types.string.isRequired,
})

export const taskDataForTaskFormShape = types.shape({
  assignee_ids: types.arrayOf(types.number),
  description: types.string,
  due_date: types.string,
  manage_access_task_ids: types.arrayOf(types.number),
  member_ids: types.arrayOf(types.number),
  notify_ids: types.arrayOf(types.number),
  subform_id: types.number,
  title: types.string,
})

export const taskFormDataShape = types.shape({
  task: types.shape({
    assignee_ids: types.arrayOf(types.number),
    description: types.string,
    due_date: types.string,
    form_submission_id: types.number,
    member_ids: types.arrayOf(types.number),
    title: types.string,
  }),
})

export const userOptionShape = types.shape({
  id: types.number.isRequired,
  fullName: types.string.isRequired,
  active: types.bool,
})

export const taskPermissionsShape = types.shape({
  canSeeDetails: types.bool.isRequired,
  canEdit: types.bool.isRequired,
  canAnswer: types.bool.isRequired,
  canChangeStatus: types.bool.isRequired,
})

export const formSubmissionShape = types.shape({
  id: types.number.isRequired,
  slug: types.string.isRequired,
  statusName: types.string.isRequired,
  createdAt: types.string.isRequired,
})

export const relatedFormSubmissionsShape = types.shape({
  createdAt: types.string.isRequired,
  form: formShape.isRequired,
  id: types.number.isRequired,
  slug: types.string.isRequired,
  statusName: types.string.isRequired,
})

export const submissionPermissionsShape = types.shape({
  canAssignTask: types.bool.isRequired,
  canAssignTaskWithSubform: types.bool.isRequired,
  canCloseSubmission: types.bool.isRequired,
})

export const categoryShape = types.shape({
  id: types.number.isRequired,
  name: types.string.isRequired,
  slug: types.string.isRequired,
  subforms: types.arrayOf(types.shape({
    id: types.number.isRequired,
    title: types.string.isRequired,
  })),
})

export const categoryOptionShape = types.shape({
  id: types.number.isRequired,
  name: types.string.isRequired,
})

export const categoryFormBuilderShape = types.shape({
  id: types.number.isRequired,
  name: types.string.isRequired,
  slug: types.string.isRequired,
})

export const currentUserShape = types.shape({
  id: types.number.isRequired,
  isPhpAdmin: types.bool.isRequired,
})

export const copyFormSourceOrganizationShape = types.shape({
  id: types.number.isRequired,
  name: types.string.isRequired,
  forms: types.arrayOf(formOptionShape),
})

export const workflowActionShape = types.shape({
  type: types.string.isRequired,
})

export const workflowConditionValueShape = types.shape({
  days: types.number,
  statusId: types.number,
  option: types.string,
})

export const workflowConditionSubPropertyShape = types.shape({
  questionUuid: types.string,
})

export const workflowConditionShape = types.shape({
  property: types.string.isRequired,
  subProperty: workflowConditionSubPropertyShape,
  operator: types.string.isRequired,
  value: workflowConditionValueShape,
})

export const workflowConditionsShape = types.shape({
  operator: types.string,
  conditions: types.arrayOf(workflowConditionShape),
})

export const questionTagShape = types.shape({
  id: types.number.isRequired,
  name: types.string.isRequired,
})

export const facilityGroupCodeShape = types.shape({
  id: types.number.isRequired,
  name: types.string.isRequired,
})

export const departmentMemberShape = types.shape({
  active: types.bool.isRequired,
  id: types.number.isRequired,
  firstName: types.string.isRequired,
  fullName: types.string.isRequired,
  lastName: types.string.isRequired,
})

export const departmentSegmentShape = types.shape({
  name: types.oneOf(DEPARTMENT_SEGMENTS).isRequired,
  members: types.arrayOf(departmentMemberShape).isRequired,
})

export const adminFacilityDepartmentShape = types.shape({
  active: types.bool.isRequired,
  id: types.number.isRequired,
  facilityId: types.number.isRequired,
  name: types.string.isRequired,
  segments: types.arrayOf(departmentSegmentShape).isRequired,
})

export const sourceListUserShape = types.shape({
  id: types.number.isRequired,
  firstName: types.string.isRequired,
  fullName: types.string.isRequired,
  lastName: types.string.isRequired,
})

export const reportExportBuilderQuestionShape = types.shape({
  addedAt: types.string.isRequired,
  prompt: types.string.isRequired,
  removedAt: types.string,
  uuid: types.string.isRequired,
})

export const questionHistoryQuestionShape = types.shape({
  addedAt: types.string.isRequired,
  prompt: types.string.isRequired,
  removedAt: types.string,
  uuid: types.string.isRequired,
})

export const reportableFieldSelectReportableFieldShape = types.shape({
  id: types.number.isRequired,
  name: types.string.isRequired,
})

export const reportExportBuilderReportableFieldShape = types.shape({
  id: types.number.isRequired,
  description: types.string,
  name: types.string.isRequired,
  questionType: types.string,
})

export const reportExportBuilderColumnOptionShape = types.shape({
  label: types.string.isRequired,
  id: types.string.isRequired,
})

export const questionBranchingOperatorDataShape = types.shape({
  /* eslint-disable-next-line react/forbid-prop-types */
  [DEPARTMENT_FACILITY_MAPPING_OPERATOR_DATA_KEY]: types.object,
})

export const featureAnnouncementShape = types.shape({
  id: types.number,
  title: types.string,
  message: types.string,
})

const addOnFeatureSubscriptionDetailsShape = types.shape({
  subscribed: types.bool,
})

// Properties in shape should match AddOnFeature::ADD_ON_FEATURE_NAMES
// (see app/models/add_on_feature.rb)
export const addOnFeatureSubscriptionsOverviewShape = types.shape({
  impersonation_by_organization_admins: addOnFeatureSubscriptionDetailsShape.isRequired,
  multifactor_authentication: addOnFeatureSubscriptionDetailsShape.isRequired,
  report_sftp: addOnFeatureSubscriptionDetailsShape.isRequired,
  scim_user_provisioning: addOnFeatureSubscriptionDetailsShape.isRequired,
  send_emails: addOnFeatureSubscriptionDetailsShape.isRequired,
  surveys: addOnFeatureSubscriptionDetailsShape.isRequired,
})

export const surveyConfigurationForTileGridShape = types.shape({
  id: types.number.isRequired,
  description: types.string,
  name: types.string.isRequired,
  surveyForm: formShape.isRequired,
  lastSentAt: types.string,
})

export const surveyConfigurationsForTileGridShape = types.arrayOf(
  surveyConfigurationForTileGridShape,
)

export const surveyBatchForExportReportShape = types.shape({
  id: types.string.isRequired,
  name: types.string,
  createdAt: types.string,
  surveyConfigurationId: types.number.isRequired,
})

export const surveyConfigurationForExportReportShape = types.shape({
  id: types.number.isRequired,
  name: types.string,
  batchAnonymous: types.bool,
  surveyBatches: types.arrayOf(surveyBatchForExportReportShape),
})

export const adminUserShowDepartment = types.shape({
  facilityId: types.number.isRequired,
  id: types.number.isRequired,
  name: types.string.isRequired,
})

export const adminUserShowDepartmentMembership = types.shape({
  id: types.number.isRequired,
  segment: types.oneOf(SEGMENTS).isRequired,
  department: adminUserShowDepartment.isRequired,
})

export const adminUserShowDepartmentMemberships = types.arrayOf(adminUserShowDepartmentMembership)

export const reportExportFormSubmissionReportShape = types.shape({
  id: types.number.isRequired,
  formSlug: types.string.isRequired,
  name: types.string.isRequired,
})

export const reportExportSolutionLevelReportShape = types.shape({
  id: types.number.isRequired,
  categorySlug: types.string.isRequired,
  name: types.string.isRequired,
})

export const reportExportSurveyReportShape = types.shape({
  id: types.number.isRequired,
  formSlug: types.string.isRequired,
  name: types.string.isRequired,
})

export const departmentShape = types.shape({
  id: types.number.isRequired,
  active: types.bool.isRequired,
  facilityId: types.number.isRequired,
  name: types.string.isRequired,
})

export const organizationAccessShape = types.shape({
  id: types.number.isRequired,
  organizationId: types.number.isRequired,
  userId: types.number.isRequired,
})

export const formSubmissionFormShape = types.shape({
  icon: types.string.isRequired,
  iconColor: types.string.isRequired,
  id: types.number.isRequired,
  slug: types.string.isRequired,
  title: types.string.isRequired,
})

export const formVersionShape = types.shape({
  id: types.number.isRequired,
  sections: types.arrayOf(sectionShape).isRequired,
})

export const surveyTokenPublicDetailsShape = types.shape({
  comment: types.string,
  contactEmail: types.string,
  contactFullName: types.string,
  reminderCCEmail: types.string,
  surveyBatchAnonymous: types.bool.isRequired,
  surveyBatchCreatedAt: types.string.isRequired,
  surveyBatchName: types.string.isRequired,
})

export const identityShape = types.shape({
  id: types.number.isRequired,
  active: types.bool.isRequired,
  email: types.string.isRequired,
  firstName: types.string,
  fullName: types.string,
  lastName: types.string,
  phpEmployee: types.bool.isRequired,
})

export const sessionOrganizationShape = types.shape({
  authenticated: types.bool.isRequired,
  organizationName: types.string.isRequired,
  organizationSubdomain: types.string.isRequired,
})
