import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { getOrganizationSubdomain } from "reduxSlices/currentSettingsSlice"
import { sessionOrganizationShape } from "utils/propTypeShapes"
import { activityHubSignInUrl } from "utils/routeHelpers"
import { CheckmarkCircleIcon } from "shared/icons"
import OrganizationSwitcher from "./OrganizationSwitcher"

const UsersPanelOrganization = ({ className = "", organization }) => {
  const {
    authenticated, organizationColor, organizationName, organizationSubdomain,
  } = organization

  const currentOrganizationSubdomain = useSelector(getOrganizationSubdomain)

  const isCurrentOrganization = currentOrganizationSubdomain === organizationSubdomain
  let orgLink

  if (authenticated) {
    orgLink = <OrganizationSwitcher organization={organization} />
  } else {
    orgLink = <a href={activityHubSignInUrl({ subdomain: organizationSubdomain })}>Sign In</a>
  }

  return (
    <div className={className}>
      <div className="flex items-center gap-2">
        <div className={clsx("bulletpoint", organizationColor)} />
        <span className={clsx(isCurrentOrganization && "font-semibold")}>{organizationName}</span>
      </div>
      {
        isCurrentOrganization
          ? <CheckmarkCircleIcon className={clsx("current-organization-checkmark", organizationColor)} />
          : orgLink
      }
    </div>
  )
}

UsersPanelOrganization.propTypes = {
  className: types.string,
  organization: sessionOrganizationShape.isRequired,
}

export default UsersPanelOrganization
