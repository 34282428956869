import React, { useState } from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import { identityShape } from "utils/propTypeShapes"
import * as API from "services/api"
import withReduxProvider from "shared/withReduxProvider"
import { errorToast, successToast } from "shared/toast"
import PhpAdminOrganizationAccessCheckbox from "./PhpAdminOrganizationAccessCheckbox"

const ManagePhpAdminOrganizationAccess = ({
  organizationSubdomain,
  phpAdmins: propsPhpAdmins,
  phpEmployeeIdentities,
}) => {
  const [phpAdmins, setPhpAdmins] = useState(propsPhpAdmins)

  const phpAdmin = (identityId) => phpAdmins.find((phpAdminUser) => phpAdminUser.identityId === identityId)

  const identityIsOrganizationPhpAdmin = (identityId) => Boolean(phpAdmin(identityId))

  const removeAccess = (identityId) => async () => {
    const user = phpAdmin(identityId)

    if (!user) return

    const { id: userId } = user
    const response = await API.deletePhpAdminUser({ userId, organizationSubdomain })

    if (response.ok) {
      setPhpAdmins((previousPhpAdmins) => (
        previousPhpAdmins.filter((phpAdminUser) => (
          phpAdminUser.identityId !== identityId
        ))
      ))
      successToast("Organization access successfully removed.")
    } else {
      errorToast("Something went wrong.  Unable to remove access.")
    }
  }

  const addAccess = (identityId) => async () => {
    const response = await API.createOrganizationAccess({ organizationSubdomain, identityId })

    if (response.ok) {
      setPhpAdmins((previousPhpAdmins) => (
        [
          ...previousPhpAdmins,
          response.data,
        ]
      ))
      successToast("Organization access successfully granted.")
    } else {
      errorToast("Something went wrong.  Unable to add access.")
    }
  }

  return (
    <div>
      {
        sortBy(phpEmployeeIdentities, ["lastName", "firstName"]).map((identity) => {
          const hasAccess = identityIsOrganizationPhpAdmin(identity.id)

          return (
            <PhpAdminOrganizationAccessCheckbox
              key={identity.id}
              hasAccess={hasAccess}
              onChange={hasAccess ? removeAccess(identity.id) : addAccess(identity.id)}
              identity={identity}
            />
          )
        })
      }
    </div>
  )
}

ManagePhpAdminOrganizationAccess.propTypes = {
  phpEmployeeIdentities: types.arrayOf(identityShape).isRequired,
  organizationSubdomain: types.string.isRequired,
  phpAdmins: types.arrayOf(types.shape({
    id: types.number.isRequired,
    identityId: types.number.isRequired,
  })).isRequired,
}

export default withReduxProvider(ManagePhpAdminOrganizationAccess)
