import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash-es";

const SLICE_NAME = "session"

const INITIAL_STATE = {
  currentUser: {},
  identity: {},
  impersonator: {},
  organizations: [],
}

export const sessionSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSession: (state, action) => {
      Object.assign(state, action.payload)
    },
    updateOrganizationColor: (state, action) => {
      const { color, organizationSubdomain } = action.payload

      const organizationToUpdate = state.organizations.find((organization) => (
        organization.organizationSubdomain === organizationSubdomain
      ))

      if (organizationToUpdate) {
        organizationToUpdate.organizationColor = color
      }
    },
  },
})

export const { setSession, updateOrganizationColor } = sessionSlice.actions

export const authenticatedOrganizations = (state) => state[SLICE_NAME].organizations.filter((organization) => organization.authenticated)

export const unauthenticatedOrganizations = (state) => state[SLICE_NAME].organizations.filter((organization) => !organization.authenticated)

export const identity = (state) => state[SLICE_NAME].identity

export const currentIdentityId = (state) => state[SLICE_NAME].identity.id

export const currentUser = (state) => state[SLICE_NAME].currentUser

export const currentUserFullName = (state) => state[SLICE_NAME].currentUser.fullName

export const currentUserId = (state) => state[SLICE_NAME].currentUser.id

export const currentUserIsInAdminRole = (state) => state[SLICE_NAME].currentUser.isInAdminRole

export const currentUserIsPhpAdmin = (state) => state[SLICE_NAME].currentUser.isPhpAdmin

export const currentUserRole = (state) => state[SLICE_NAME].currentUser.role

export const impersonationInProgress = (state) => !isEmpty(state[SLICE_NAME].impersonator)

export default sessionSlice.reducer
