import React from "react"
import { useSelector } from "react-redux"
import { getOrganizationSubdomain } from "reduxSlices/currentSettingsSlice"
import { currentIdentityId } from "reduxSlices/sessionSlice"
import { getLocationCookieValue, setLocationCookie } from "utils/locationSaverHelpers"
import { sessionOrganizationShape } from "utils/propTypeShapes"
import { activityHubDashboardUrl } from "utils/routeHelpers"

const OrganizationSwitcher = ({ organization }) => {
  const { organizationSubdomain: targetOrganizationSubdomain } = organization
  const currentOrganizationSubdomain = useSelector(getOrganizationSubdomain)
  const identityId = useSelector(currentIdentityId)

  const targetOrganizationLocation = getLocationCookieValue(targetOrganizationSubdomain, identityId)
  const targetOrganizationDashboard = activityHubDashboardUrl({ subdomain: targetOrganizationSubdomain })

  const authenticatedLinkLocation = targetOrganizationLocation || targetOrganizationDashboard

  const switchOrganizations = () => {
    setLocationCookie(currentOrganizationSubdomain, identityId)

    window.location = authenticatedLinkLocation
  }

  return (
    <button onClick={switchOrganizations}>
      Switch
    </button>
  )
}

OrganizationSwitcher.propTypes = {
  organization: sessionOrganizationShape.isRequired,
}

export default OrganizationSwitcher
