import React from "react"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import { getColor, getOrganizationSubdomain, setColor } from "reduxSlices/currentSettingsSlice"
import { updateOrganizationColor } from "reduxSlices/sessionSlice"
import { useOncePerPageLoadMessage } from "hooks"
import * as API from "services/api"
import ItemRow from "shared/ItemRow"
import { errorToast } from "shared/toast"

// Color names are defined for Tailwind in app/javascript/stylesheets/tailwind.config.js
// and for the backend in app/models/concerns/organization/colorable.rb
// Listing the colors as utility classes so that Tailwind picks them up.
const ORGANIZATION_COLOR_BACKGROUND_CLASSES = [
  "bg-organization-blue",
  "bg-organization-blue-violet",
  "bg-organization-burgundy",
  "bg-organization-green",
  "bg-organization-teal",
  "bg-organization-brick-red",
]

const COLOR_CHANGE_CONFIRMATION_MESSAGE = "Are you sure you want to change the color?\n\n"
  + "Changing the organization's color is an immediate change that affects all users."

const ColorSetting = () => {
  const dispatch = useDispatch()

  const { confirmMessage: confirmColorChange, messageAcknowledged } = useOncePerPageLoadMessage(COLOR_CHANGE_CONFIRMATION_MESSAGE, "color-change")

  const currentOrganizationColor = useSelector(getColor)
  const currentOrganizationSubdomain = useSelector(getOrganizationSubdomain)
  const colorMatcher = new RegExp(`${currentOrganizationColor}$`)

  const updateColor = (newColor) => async () => {
    if (newColor === currentOrganizationColor) return

    if (messageAcknowledged() || confirmColorChange()) {
      const response = await API.updateOrganizationColor({ color: newColor })

      if (response.ok) {
        document.body.classList.add(newColor)
        document.body.classList.remove(currentOrganizationColor)

        dispatch(setColor(newColor))
        dispatch(updateOrganizationColor({ color: newColor, organizationSubdomain: currentOrganizationSubdomain }))
      } else {
        errorToast("Something went wrong.  Unable to update organization color.")
      }
    }
  }

  return (
    <ItemRow
      className="dark content-bar"
      itemRowHeaderContent={(
        <>
          <span className="content-bar-title">Organization Color</span>
          <div className="flex">
            {
              ORGANIZATION_COLOR_BACKGROUND_CLASSES.map((bgColor) => (
                <div
                  key={bgColor}
                  className={clsx(
                    "w-9 h-9 border-4 rounded-md",
                    bgColor.match(colorMatcher)
                      ? "border-lightgray-4 p-px"
                      : "border-transparent last:-mr-1",
                  )}
                >
                  <button
                    aria-label={`Set organization color to ${bgColor.slice(3).replaceAll("-", " ")}`}
                    className={clsx(bgColor, "w-full h-full", bgColor.match(colorMatcher) ? "rounded-sm" : "rounded-md")}
                    onClick={updateColor(bgColor.slice(3))}
                  />
                </div>
              ))
            }
          </div>
        </>
      )}
    />
  )
}

export default ColorSetting
