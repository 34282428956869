import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { ProfileIcon } from "shared/icons"

const PanelTrigger = ({ selector }) => {
  const user = useSelector(selector, isEqual)

  return (
    <ProfileIcon
      className="cursor-pointer w-8 h-8 users-panel-trigger-profile-icon"
      setBackgroundColor={false}
      setForegroundColor={false}
      user={user}
    />
  )
}

PanelTrigger.propTypes = {
  selector: types.func.isRequired,
}

export default PanelTrigger
