import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { identity as getIdentity } from "reduxSlices/sessionSlice"
import { identityShape } from "utils/propTypeShapes"
import Tooltip, { RIGHT_CENTER } from "shared/Tooltip"
import { Checkbox } from "shared/checkboxes"

const PhpAdminOrganizationAccessCheckbox = ({ hasAccess, onChange, identity }) => {
  const currentIdentity = useSelector(getIdentity, isEqual)
  const disabled = !hasAccess && currentIdentity.id === identity.id

  return (
    <div className="flex items-center">
      <Tooltip
        position={RIGHT_CENTER}
        text={disabled && "You may not grant access to yourself"}
      >
        <Checkbox
          className="mr-2 cursor-pointer"
          disabled={disabled}
          onChange={onChange}
          uuid={`php-admin-organization-access-checkbox-${identity.id}`}
          value={hasAccess}
        />
        <label
          htmlFor={`php-admin-organization-access-checkbox-${identity.id}`}
          className={clsx(disabled ? "cursor-not-allowed" : "cursor-pointer")}
        >
          {identity.fullName}
        </label>
      </Tooltip>
    </div>
  )
}

PhpAdminOrganizationAccessCheckbox.propTypes = {
  hasAccess: types.bool.isRequired,
  onChange: types.func.isRequired,
  identity: identityShape.isRequired,
}

export default PhpAdminOrganizationAccessCheckbox
