import React from "react"
import types from "prop-types"
import { isEmpty, isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import {
  authenticatedOrganizations as selectAuthenticatedOrganizations,
  unauthenticatedOrganizations as selectUnauthenticatedOrganizations,
} from "reduxSlices/sessionSlice"
import { ProfileIcon } from "shared/icons"
import UsersPanelOrganizationList from "./UsersPanelOrganizationList"
import Logout from "./Logout"

const CurrentUser = ({ selector }) => {
  const currentUser = useSelector(selector, isEqual)
  const authenticatedOrganizations = useSelector(selectAuthenticatedOrganizations, isEqual)
  const unauthenticatedOrganizations = useSelector(selectUnauthenticatedOrganizations, isEqual)

  return (
    <>
      <div className="mb-8">
        <p className="section-name">My Account</p>
        <div className="bg-white rounded-md flex-grow flex items-center gap-4">
          <ProfileIcon className="w-12 h-12 shrink-0" user={currentUser} />
          <div className="flex flex-col break-all">
            <span className="text-base font-semibold">{ currentUser.fullName }</span>
            <span className="text-sm text-gray">{ currentUser.email }</span>
          </div>
        </div>
      </div>
      {
        !isEmpty(authenticatedOrganizations) && (
          <div className="mb-8">
            <p className="section-name">My Activity Hubs</p>
            <UsersPanelOrganizationList
              className="organization-list"
              organizations={authenticatedOrganizations}
            />
          </div>
        )
      }
      {
        !isEmpty(unauthenticatedOrganizations) && (
          <div className="mb-8">
            <p className="section-name">Sign In</p>
            <UsersPanelOrganizationList
              className="organization-list"
              organizations={unauthenticatedOrganizations}
            />
          </div>
        )
      }
      <Logout className="w-full" />
    </>
  )
}

CurrentUser.propTypes = {
  selector: types.func.isRequired,
}

export default CurrentUser
