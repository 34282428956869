import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getOrganizationShortName } from "reduxSlices/currentSettingsSlice"
import { currentUser, identity } from "reduxSlices/sessionSlice"
import { endAllTabSessionsListener } from "utils/endSession"
import { allTabImpersonationsListener } from "utils/impersonation"
import GlobalHeaderDropdown from "shared/headers/GlobalHeader/GlobalHeaderDropdown"
import withReduxProvider from "shared/withReduxProvider"
import Panel from "./Panel"
import PanelTrigger from "./PanelTrigger"

const UsersPanel = ({ useIdentity = false }) => {
  useEffect(() => {
    endAllTabSessionsListener()
    allTabImpersonationsListener()
  })

  const profileIconSelector = useIdentity ? identity : currentUser

  const organizationShortName = useSelector(getOrganizationShortName)

  return (
    <GlobalHeaderDropdown
      dropdownStyles="users-panel-dropdown"
      trigger={(
        <div className="users-panel-trigger">
          <span className="users-panel-trigger-short-name">{organizationShortName}</span>
          <PanelTrigger selector={profileIconSelector} />
        </div>
      )}
    >
      <Panel selector={profileIconSelector} />
    </GlobalHeaderDropdown>
  )
}

UsersPanel.propTypes = {
  useIdentity: types.bool,
}

export default withReduxProvider(UsersPanel)
