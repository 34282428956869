const DOMAIN = window?.location.hostname.split(".").slice(1).join(".")
const ACTIVITY_HUB_PATH = "/activity-hub"
const SESSION_MAX_SECONDS = 1500 // 25 minutes

const identitySuffix = (identityId) => `-identity-${identityId}`

export const getLocationCookieName = (subdomain, identityId) => `${subdomain}-location${identitySuffix(identityId)}`

export const getLocationCookieValue = (subdomain, identityId) => document.cookie
  .split("; ")
  .find((cookie) => cookie.startsWith(`${getLocationCookieName(subdomain, identityId)}=`))
  ?.split("=")[1]

export const setLocationCookie = (subdomain, identityId) => {
  document.cookie = `${getLocationCookieName(subdomain, identityId)}=${window.location.href}; max-age=${SESSION_MAX_SECONDS}; path=${ACTIVITY_HUB_PATH}; domain=${DOMAIN}`
}

export const expireActivityHubCookies = () => {
  document.cookie.split(";").forEach((cookie) => {
    const cookieName = cookie.split("=")[0].trim()

    document.cookie = `${cookieName}=; max-age=0; path=${ACTIVITY_HUB_PATH}; domain=${DOMAIN}`
  })
}

export const refreshActivityHubCookies = () => {
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = `${cookie}; max-age=${SESSION_MAX_SECONDS}; path=${ACTIVITY_HUB_PATH}; domain=${DOMAIN}`
  })
}
