import React from "react"
import types from "prop-types"
import { sessionOrganizationShape } from "utils/propTypeShapes"
import UsersPanelOrganization from "./UsersPanelOrganization"

const UsersPanelOrganizationList = ({ className = "", organizations }) => (
  <ul className={className}>
    {
      organizations.map((organization) => (
        <li key={organization.organizationSubdomain}>
          <UsersPanelOrganization className="organization" organization={organization} />
        </li>
      ))
    }
  </ul>
)

UsersPanelOrganizationList.propTypes = {
  className: types.string,
  organizations: types.arrayOf(sessionOrganizationShape).isRequired,
}

export default UsersPanelOrganizationList
