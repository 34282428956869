import React, { useEffect, useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { impersonationInProgress } from "reduxSlices/sessionSlice"
import { SURVEY_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import withReduxProvider from "shared/withReduxProvider"
import { PHPLogo } from "shared/icons"
import GlobalHeaderTabs from "./GlobalHeaderTabs"
import FeatureAnnouncementsPanel from "../../FeatureAnnouncementPanel"
import UsersPanel from "../UsersPanel"
import TasksAndSubmissionsSearch from "./TasksAndSubmissionsSearch"
import { GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID } from "./GlobalHeaderChildrenPortal"
import ImpersonationBanner from "../ImpersonationBanner"
import MobileMenuButton from "./MobileMenuButton"

const GlobalHeader = ({ currentTab = "", additionalTabs = [] }) => {
  const showImpersonationBanner = useSelector(impersonationInProgress)

  const [searchVisible, setSearchVisible] = useState(false)
  const [mobileMenuVisible, setMobileMenuVisible] = useState(window.innerWidth > 1024)

  const showMobileMenu = () => setMobileMenuVisible(true)
  const hideMobileMenu = () => setMobileMenuVisible(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        showMobileMenu()
      } else {
        hideMobileMenu()
      }
    }
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <div
      className={clsx(
        "global-header",
        additionalTabs.includes(SURVEY_FEATURE_NAME) && "surveys",
        additionalTabs.includes("admin") && "admin",
      )}
    >
      {
        showImpersonationBanner && (
          <div className="global-header-impersonation-banner">
            <ImpersonationBanner />
          </div>
        )
      }
      <nav className="global-header-nav">
        <PHPLogo />

        <GlobalHeaderTabs currentTab={currentTab} additionalTabs={additionalTabs} mobileMenuVisible={mobileMenuVisible} />
        <div id={GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID} />
        <div className="global-header-nav-buttons">
          <div className="global-header-nav-buttons-dropdowns">
            <TasksAndSubmissionsSearch
              searchVisible={searchVisible}
              setSearchVisible={setSearchVisible}
            />
            <FeatureAnnouncementsPanel />
            <UsersPanel />
          </div>

          <MobileMenuButton menuIsOpen={mobileMenuVisible} onClick={mobileMenuVisible ? hideMobileMenu : showMobileMenu} />
        </div>
      </nav>
    </div>
  )
}

GlobalHeader.propTypes = {
  currentTab: types.node,
  additionalTabs: types.arrayOf(types.string),
}

export default withReduxProvider(GlobalHeader)
