import React from "react"
import ColorSetting from "./ColorSetting"
import ShortName from "./ShortName"
import TimezoneSetting from "./TimezoneSetting"
import TaskRemindersSetting from "./TaskRemindersSetting"
import TaskNotificationSettings from "./TaskNotificationSettings"

export const GENERAL_TAB_HEADING = "General"

const GeneralTab = () => (
  <>
    <h2 className="tab-title">{ GENERAL_TAB_HEADING }</h2>
    <TimezoneSetting />
    <ColorSetting />
    <ShortName />
    <TaskRemindersSetting />
    <TaskNotificationSettings />
  </>
)

export default GeneralTab
