import React from "react"
import types from "prop-types"
import { endSession } from "utils/endSession"
import { TertiaryButton } from "shared/buttons"

const Logout = ({ className = "" }) => (
  <TertiaryButton
    className={className}
    onClick={endSession}
    type="submit"
    text="Logout"
  />
)

Logout.propTypes = {
  className: types.string,
}

export default Logout
