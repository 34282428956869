import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const CheckmarkCircleIcon = ({ className = "w-8 h-8" }) => <SvgSpriteIcon iconName="checkmark-circle" className={clsx("checkmark-circle", className)} />

CheckmarkCircleIcon.propTypes = {
  className: types.string,
}

export default CheckmarkCircleIcon
