import React from "react"
import { useSelector } from "react-redux"
import { getTaskNotifySettings } from "reduxSlices/currentSettingsSlice"
import { notificationSettingLabel } from "utils/generalSettingsHelpers"
import ItemRow from "shared/ItemRow"
import NotificationSetting from "./NotificationSetting"

const TaskNotificationSettings = () => {
  const taskNotifySettings = useSelector(getTaskNotifySettings)

  if (taskNotifySettings?.size === 0) return null

  return (
    <ItemRow
      className="dark content-bar"
      itemRowHeaderContent={<span className="content-bar-title">Task Notification Defaults</span>}
      itemRowBody={(
        <>
          <div className="flex flex-col mb-4">
            {
              taskNotifySettings.map(({ settingType }) => (
                <NotificationSetting
                  key={settingType}
                  settingType={settingType}
                  label={notificationSettingLabel(settingType)}
                />
              ))
            }
          </div>
          <p className="text-xs">These defaults only affect initial settings when creating a Task or Workflow</p>
        </>
      )}
    />
  )
}

export default TaskNotificationSettings
