import React from "react"
import types from "prop-types"
import CurrentUser from "./CurrentUser"

const Panel = ({ selector }) => (
  <CurrentUser selector={selector} />
)

Panel.propTypes = {
  selector: types.func.isRequired,
}

export default Panel
