import React from "react"
import types from "prop-types"
import AuditItem from "./AuditItem"
import EmptyAudits from "./EmptyAudits"

const SignInHistoryTab = ({ signInAudits }) => {
  if (signInAudits.length === 0) {
    return <EmptyAudits />
  }

  return (
    <>
      <h2 className="tab-title">Sign-in History</h2>
      <table className="w-full mb-6 border-separate" style={{ borderSpacing: "0 0.5rem" }}>
        <thead>
          <tr>
            <th className="text-left">Action</th>
            <th className="text-left">Date</th>
            <th className="text-left">IP Address</th>
            <th className="text-left">Impersonator</th>
            <th className="text-left">Organization</th>
          </tr>
        </thead>
        <tbody>
          {
            signInAudits.map((audit, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <AuditItem key={index} audit={audit} />
            ))
          }
        </tbody>
      </table>
    </>
  )
}

SignInHistoryTab.propTypes = {
  signInAudits: types.arrayOf(types.object).isRequired,
}

export default SignInHistoryTab
