import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { endSession } from "utils/endSession"
import { refreshActivityHubCookies } from "utils/locationSaverHelpers"
import IdleTracker from "./IdleTracker"
import SessionTimeoutModal from "./SessionTimeoutModal"

// The rails session cookie is valid for 25 minutes (see config/initializers/session_store.rb).
// To accommodate the 1 minute countdown the user has to click "Stay logged in",
// setting the default idle time to 23.5 minutes.
const DEFAULT_IDLE_TIME_IN_MINUTES = 23.5
const MILLISECONDS_IN_ONE_MINUTE = 60000

const SessionIdleTracker = ({ refreshPath }) => {
  // To refresh a session, make a request to the server.
  // If the request is successful, then reset the timer.
  // If the request is unsuccessful, end the user's session.
  const refreshSession = async (resetTimer) => {
    const response = await API.refreshSession(refreshPath)

    if (response.ok) {
      resetTimer()
      refreshActivityHubCookies()
    } else {
      endSession()
    }
  }

  return (
    <IdleTracker
      idleTime={DEFAULT_IDLE_TIME_IN_MINUTES * MILLISECONDS_IN_ONE_MINUTE}
      onContinue={refreshSession}
      onTimeout={endSession}
      modal={SessionTimeoutModal}
    />
  )
}

SessionIdleTracker.propTypes = {
  refreshPath: types.string.isRequired,
}

export default SessionIdleTracker
